import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

import rakesh from "../assets/rakesh sir new.jpg";
import ayush from "../assets/Ayush sir growfi.png";

const OurTeam = () => {
  const teamDetails = [
    {
      imageLink: rakesh,
      name: "Rakesh Sharma",
      email: "",
      position: "Founder & CEO",
      description:
        "An accomplished IIT Roorkee Alumni with extensive experience spanning 18 years in IT Consulting and Fintech, notably as a patent holder in AI and Blockchain technologies. Previously held positions at Accenture and Stride Ventures.",
    },
    {
      imageLink: ayush,
      name: "Ayush Arora",
      email: "",
      position: "CFO",
      description:
        "An accomplished IIT Guwahati and XLRI Alumni, a serial entrepreneur, and a former Co-Founder of Sattviko. Previous experience includes a tenure at Airtel. Bringing over 12 years of expertise spanning Treasury, FMCG, and specialized knowledge in Liquidity and Investments.",
    },
  ];

  return (
    <div className="flex justify-center items-center md:px-14 p-4 max-w-s mx-auto py-10 mb-16 shadow-blue-100 shadow-sm">
      <motion.div
        variants={fadeIn("up", 0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="text-center"
      >
        <h2 className="md:text-5xl text-3xl font-bold text-[#08ce8a] mb-2">
          FOUNDING TEAM
        </h2>
        <h3 className="md:text-xl text-2xl font-semibold text-black mb-2">
          The amazing team behind GrowFi
        </h3>
      </motion.div>

      {/* Team members */}
      <div className="w-full flex justify-center">
        <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-10 mt-20 w-full md:w-10/12 lg:w-9/12">
          {teamDetails.map((member, index) => (
            <a
              href={`mailto:${member.email}`}
              className="border py-10 md:px-6 px-4 rounded-lg shadow-3xl"
              key={index}
            >
              <div className="flex justify-center items-center">
                <img
                  className="lg:max-w-[50%] w-auto h-52"
                  src={member.imageLink}
                  alt="team member"
                />
              </div>
              <h3 className="text-3xl font-bold text-center text-black">
                {member.name}
              </h3>
              <h4 className="text-2xl font-semibold text-center text-gray-500">
                {member.position}
              </h4>
              <p className="text-gray-700 text-center my-5">
                {member.description}
              </p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
